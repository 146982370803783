import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom"
import './App.css';
import { Container } from 'react-bootstrap';
import Home from './pages/Home';

function App() {
  return (
    <Container fluid className="m-0">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>,
    </Container>
  );
}

export default App;
