import React from 'react';
import logo from '../assets/logo/emblem_dark.png';

class Home extends React.Component {
  render() {   
    return (
      <>  
        <div className="text-center d-flex" style={{height: '100vh'}}>
          <div className='m-auto align-self-center p-5'>
            <img src={logo} width='50px' alt='Electrum Network' />
          </div>
        </div>
      </>
    );
  }
}

export default Home;
